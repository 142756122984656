import * as React from "react";

import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "./components/AppNavbar";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import AppTheme from "../shared-theme/AppTheme";
import { MyProvider } from "./components/MyContext";
import TabContent from "./components/TabContent";
import { useParams } from "react-router-dom";

export default function PageMain(props) {
  // 拿到 bookID
  const { bookId } = useParams();
  const { keywords } = useParams();
  React.useEffect(() => {
    console.log("PageMain: bookId: ", bookId);
    console.log("PageMain: keywords: ", keywords);
  }, [bookId, keywords]);

  return (
    <MyProvider>
      <AppTheme {...props}>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: "flex" }}>
          <SideMenu />
          <AppNavbar />
          {/* Main content */}
          <Box
            component="main"
            sx={(theme) => ({
              flexGrow: 1,
              backgroundColor: theme.vars
                ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                : alpha(theme.palette.background.default, 1),
              overflow: "auto",
            })}
          >
            <Stack
              spacing={2}
              sx={{
                alignItems: "left",
                mx: 3,
                pb: 5,
                mt: { xs: 8, md: 0 },
              }}
            >
              <Header />
              {bookId !== undefined && <TabContent bookID={bookId} />}
              {keywords !== undefined && <TabContent keywords={keywords} />}
              {bookId === undefined && keywords === undefined && <TabContent />}
            </Stack>
          </Box>
        </Box>
      </AppTheme>
    </MyProvider>
  );
}
