import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
//import SearchIcon from "@mui/icons-material/Search";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import "./MenuContent.css";
import Divider from "@mui/material/Divider";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useContext } from "react";
import { MyContext } from "./MyContext";

export default function MenuContent() {
  const {
    isLogin,
    setIsLogin,
    curTitle,
    setCurTitle,
    setCurBookID,
    setCurCollectionID,
  } = useContext(MyContext);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLogin(Cookies.get("usertoken") != null);
    if (!isLogin) {
      setCurBookID("");
      setCurCollectionID("");
      setCurTitle("Home");
    }
  }, [isLogin, setCurBookID, setCurCollectionID, setCurTitle, setIsLogin]);

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List dense className="FirstMenu">
        <ListItem key={0} disablePadding sx={{ display: "none" }}>
          {/* 这个第一个 MVP 版本先隐藏 */}
          <ListItemButton
            selected={curTitle === "For You"}
            onClick={() => {
              if (!isLogin) {
                setCurBookID("");
                setCurCollectionID("");
                setCurTitle("Signin");
              } else {
                navigate("/foryou");
              }
            }}
          >
            <ListItemIcon>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="For You" />
          </ListItemButton>
        </ListItem>
        <ListItem key={1} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={curTitle === "Home"}
            onClick={() => {
              setCurTitle("Home");
              navigate("/");
            }}
          >
            <ListItemIcon>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        {isLogin ? (
          <ListItem key={2} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              selected={curTitle === "My Library"}
              onClick={() => {
                if (!isLogin) {
                  setCurBookID("");
                  setCurCollectionID("");
                  setCurTitle("Signin");
                } else {
                  navigate("/mylib");
                }
              }}
            >
              <ListItemIcon>
                <BookmarkIcon />
              </ListItemIcon>
              <ListItemText primary="My Library" />
            </ListItemButton>
          </ListItem>
        ) : null}
      </List>
      <Divider />
      {
        /* 登录面板 */
        isLogin ? null : (
          <Stack>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setCurBookID("");
                setCurCollectionID("");
                setCurTitle("Signup");
              }}
            >
              Sign up
            </Button>
            <Box sx={{ mb: 2 }} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCurBookID("");
                setCurCollectionID("");
                setCurTitle("Signin");
              }}
            >
              Sign in
            </Button>
          </Stack>
        )
      }
      <List dense>
        {
          /* 登录后的设置面板 */
          isLogin ? (
            <ListItem key={0} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={curTitle === "Settings"}
                onClick={() => {
                  setCurBookID("");
                  setCurCollectionID("");
                  setCurTitle("Settings");
                }}
              >
                <ListItemIcon>
                  <SettingsRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItemButton>
            </ListItem>
          ) : null
        }

        <ListItem key={1} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => {
              window.open("https://insightreader.ai/about", "_blank");
            }}
          >
            <ListItemIcon>
              <InfoRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={"About"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={2} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => {
              window.location.href = "mailto:contact@insightreader.ai";
            }}
          >
            <ListItemIcon>
              <HelpRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={"Feedback"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );
}
